/* eslint-disable react/no-danger */
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  Typography,
  Tooltip as MUITooltip,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Link,
} from '@material-ui/core'
import { userRoleSelector, userSelector } from '_modules/authentication/selectors'
import { useLocation } from '@reach/router'
import Button from '_components/button'
import { viewTip } from '_modules/tip/actions'

import useStyles from './styles'

const Tips = ({ component = '', children, placement }) => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const user = useSelector(userSelector)
  const userRole = useSelector(userRoleSelector)
  const { pathname } = useLocation()

  const [showTooltip, setShowTooltip] = useState(false)
  const [checkbox, setCheckbox] = useState(false)

  const tip = useMemo(() => {
    return (
      user &&
      user?.tips?.filter(tipToSee => {
        const isRightScreen = tipToSee.screens.some(
          screen => screen?.route === pathname || screen?.route === ''
        )

        return (
          isRightScreen &&
          tipToSee?.component === component &&
          (Array.isArray(user?.getRoles) ? user.getRoles : [user?.getRoles]).some(role =>
            (tipToSee?.getRoles || []).includes(role)
          )
        )
      })[0]
    )
  }, [user, userRole, pathname])

  const handleClick = useCallback(() => {
    if (checkbox) {
      dispatch(viewTip({ tips: tip?.id, user: user?.id }))
    }

    setShowTooltip(false)
  }, [dispatch, tip, user, checkbox])

  const renderTip = useMemo(() => {
    return (
      <span className={styles.wrapper}>
        <Typography className={styles.title}>{tip?.title}</Typography>
        <span className={styles.message} dangerouslySetInnerHTML={{ __html: tip?.content }} />
        <FormGroup>
          <FormControlLabel
            value={checkbox}
            onChange={() => setCheckbox(prevState => !prevState)}
            label="Não me mostrar novamente."
            style={{
              color: '#434343',
            }}
            control={<Checkbox color="primary" />}
          />
        </FormGroup>
        <Grid className={styles.buttonsContainer}>
          {tip?.knowMoreUrl && (
            <Button className={styles.knowMoreButton} variant="text" color="primary">
              <Link target="_blank" rel="noopener" href={tip.knowMoreUrl}>
                Saiba mais
              </Link>
            </Button>
          )}
          <Button
            onClick={handleClick}
            className={styles.understoodButton}
            variant="outlined"
            color="primary"
          >
            Entendi
          </Button>
        </Grid>
      </span>
    )
  }, [tip, handleClick, checkbox])

  useEffect(() => {
    tip?.title && setShowTooltip(true)
  }, [tip])

  return tip?.title ? (
    <MUITooltip
      arrow
      open={showTooltip}
      title={renderTip}
      classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
      placement={placement}
    >
      {children}
    </MUITooltip>
  ) : (
    <>{children}</>
  )
}

Tips.propTypes = {
  children: PropTypes.node.isRequired,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
}

Tips.defaultProps = {
  placement: 'bottom',
}

export default React.memo(Tips)
