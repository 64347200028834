import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

// NOTE: We have to use !important because MUI prop classes doesn't overwrite all rules
export default makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '600px',
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '4px',
    padding: '12px 16px 12px 16px',
    backgroundColor: `rgb(254, 250, 235) !important`,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.6)',
    pointerEvents: 'visible',

    '& .MuiTooltip-arrow:before': {
      backgroundColor: `rgb(254, 250, 235) !important`,
    },
  },
  title: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    fontWeight: '700',
    color: '#434343',
    fontSize: '16px',
    lineHeight: '140%',
    marginBottom: '10px',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    fontSize: '14px',
    lineHeight: '140%',
    color: '#434343',
  },
  knowMoreButton: {
    fontFamily: Theme.Typography.FontFamily.DMSans,
    color: '#1671ED',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '12px',
    textDecorationLine: 'underline',
    alignItems: 'center',
    padding: '8px 12px',
    gap: '10px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
    marginTop: '5px',
  },
  understoodButton: {
    width: '83px',
    height: '36px',
  },
  arrow: {
    color: '#FFFFFF !important',

    '&::before': {
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.6)',
    },
  },
}))
